/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scroll {
  overflow: hidden;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

.swiper-button-next {
  background: white;
  padding: 16px;
  color: #111;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  right: 24px;
}

.swiper-button-next::after {
  font-size: 16px;
}

.swiper-button-prev {
  background: white;
  padding: 16px;
  color: #111;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  left: 24px;
}

.swiper-button-prev::after {
  font-size: 16px;
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode), .video-js.vjs-16-9:not(.vjs-audio-only-mode), .video-js.vjs-4-3:not(.vjs-audio-only-mode), .video-js.vjs-9-16:not(.vjs-audio-only-mode), .video-js.vjs-1-1:not(.vjs-audio-only-mode) {
  height: 100% !important;
}

.videojs-modal {
  @apply absolute top-0 left-0 w-full object-cover h-[calc(100%_-_24px)] mt-3 rounded-lg bg-transparent overflow-hidden
  !important;

  video {
    @apply object-cover
    !important;
  }
}
